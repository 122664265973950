import React, { useState, useEffect } from "react";
import Seo from "/src/components/helpers/seo";
import { Grid, PaginationWrapper } from "./EventsList.styles";
import ReactPaginate from "react-paginate";
import { useQueryParam, StringParam } from "use-query-params";
import {Link} from 'gatsby';

const truncate = (str) => {
  return str.length > 220 ? str.substring(0, 230) + "..." : str;
};

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((article) => (

          <li key={article.path.alias} class="mb-4">
          <Link to={article.path.alias} class="border-gray-400 flex flex-row no-underline">
            <div class="shadow border select-none cursor-pointer dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                    <a href="#" class="block relative">
                        <img alt={article.title} src={article.relationships.field_image.image_style_uri.image_75x75} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                    </a>
                </div>
                <div class="flex-1 pl-1 md:mr-16">
                    <div class="font-medium dark:text-white">
                    {article.title}
                    </div>
                    <div class="text-gray-800 italic dark:text-gray-400 text-sm">
                    Author: {article.relationships.field_author.title}
                    </div>
                    <div class="text-gray-600 dark:text-gray-200 text-sm" dangerouslySetInnerHTML={{__html: truncate(article.body.processed)}} />            
                </div>
            </div>
            </Link>
        </li>
        ))}
    </>
  );
}

const EventsList = ({ data }) => {
  const [searchValue, setsearchValue] = useQueryParam("search", StringParam) || '';
  const allItems = data.allNodeEvent.nodes;
  let items = allItems;

  useEffect(() => {
  if (typeof searchValue != 'undefined' && searchValue.length > 0) {
    items = allItems.filter((event) => {
    return (
      event.title.toLowerCase().includes(searchValue.toLowerCase())
      //  || event.body.processed.toLowerCase().includes(searchValue.toLowerCase())
    );
  }); 
  } else {
      items = allItems;
  }
}, [searchValue, items]);

  const itemsPerPage = 6;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };
  const currentPage = itemOffset / itemsPerPage + 1;

  console.log("Search value is: " + searchValue);
  return (
    <>
      <Seo title="Index EN" />

      <div class="bg-white dark:bg-gray-800 ">
    <div class="text-center w-full mx-auto py-4 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h1 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
              List of events
            </span>
            {typeof searchValue != 'undefined' && searchValue.length > 0 && (
              <span class="block text-indigo-500">Search value is: {searchValue}</span>
      )}
            <span class="block text-base text-indigo-500">
            Displaying page {currentPage} out of {pageCount}
            </span>
            
        </h1>
    </div>
</div>

      
      

      <div class="container flex flex-col mx-auto w-full items-center justify-center">
    <ul class="flex flex-col">
        <Items currentItems={currentItems} />
        </ul>
</div>

      <PaginationWrapper>
        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </PaginationWrapper>
    </>
  );
};

export default EventsList;
