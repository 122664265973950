import React from "react";
import { Layout } from "/src/components/layouts/default";
import Seo from "/src/components/helpers/seo";
import EventsList from "/src/components/views/EventsList/EventsList" 
import { graphql } from "gatsby";
const EventsPage = ({ data }) => {
  
  return (
    <Layout>
      <Seo title="Events " />
    <EventsList data={data} />
    </Layout>
  );
};

export default EventsPage;

export const query = graphql`
  query EventsView {
    allNodeEvent {
      nodes {
        title
        path {
          alias
        }
        relationships {
          field_image {
            image_style_uri {
              image_75x75
            }
          }
          field_author {
            title
            relationships {
              field_image {
                image_style_uri {
                  thumbnail
                }
              }
            }
          }
        }
        body {
          processed
        }
        langcode
      }
    }
  }
`;
