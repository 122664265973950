import styled from "styled-components";
//import { Link as GatsbyLink } from "gatsby";

// export const StyledLink = styled(GatsbyLink)`
//   display: flex;
//   text-decoration: none;
//   margin-bottom: 20px;
//   color: black;
//   .title {
//     color: black;
//     padding: 10px 0;
//     font-size: 20px;
//   }
//   .text {
//     margin-left: 20px;
//     font-size: 14px;
//   }
// `;

export const Grid = styled.div`
  display: block;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const PaginationWrapper = styled.div`
  margin: 40px auto 100px;
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
`;
